
const initState = () => ({
    productDetail: null,
})

export const state = initState

export const getters = {}

export const mutations = {
    setProduct(state, {product}){
        state.productDetail = product
    },
    deleteProduct(state){
        state.productDetail = null
    }
}

export const actions = {
    
}
